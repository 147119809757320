article, 
aside, 
details, 
figcaption, 
figure, 
footer, 
header, 
hgroup, 
main, 
menu, 
nav, 
section, 
summary                                   { display: block; }
body                                      { height: 1000px; background-color: #ecf0f5; }

.react-select__control                    { font-family: "Roboto","Helvetica","Arial",sans-serif !important; padding: 4.5px 4px 8px !important; height: 3.4375em !important; margin: 0 !important; -webkit-tap-highlight-color: transparent !important; min-width: 0 !important; -webkit-animation-name: mui-auto-fill-cancel !important; animation-name: mui-auto-fill-cancel !important; -webkit-animation-duration: 10ms !important; animation-duration: 10ms !important; padding-right: 0 !important; }
.react-select__control:hover              { border-color: #000 !important;  }
.react-select__control:focus              { border-color: blue !important;  }
.react-select__menu                       { z-index: 2000 !important; }

.react-select__indicators                 { height: 150% !important;  margin: -10px 0 !important; }


html                                      { overflow-y: scroll; }
html,
body                                      { margin:0; padding:0; background-color: #222d32 !important; }
body:not(.layout-fixed) .main-sidebar     { height: inherit; min-height: 100%; position: absolute; top: 0; }

@media (min-width: 768px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    margin-left: 270px;
  }
}

a                                         { text-decoration:none; cursor:pointer; }
a:active,
a:hover                                   { color: #dd4b39; }
label                                     { font-weight:normal; padding-top:0.33em; }
h1                                        { font-size: 24px; }
h2                                        { font-size: 20px; }
h3                                        { font-size: 18px; }
h4                                        { font-size: 16px; color: #8aa4af; margin:0; }
h5                                        { font-size: 14px; color: #8aa4af; margin:0; }

.absolute                                 { position:absolute; }
.action-cell button                       { min-width: 36px; }
.action-cell .dropdown-menu               { min-width:60px !important; width: 75px !important; background-color: #333; }
.action-cell .dropdown-menu>li>a          { padding:3px 10px; color:#b8c7ce; font-size:12px; }
.action-cell .dropdown-menu>li>a>.glyphicon { padding:3px 0; color:#b8c7ce; font-size:10px; }
.action-cell .dropdown-menu>li>a:hover    { background-color:#222d32; color:#ffffff; }
.action-cell .dropdown-menu>li>a:hover>.glyphicon { background-color:#222d32; color:#ffffff; }

.action-icon					                    { text-align:center; flex:0 0 50px !important; }
.action-icon.rt-td		                    { cursor:pointer; }
.active-indicator                         { margin-right: .5em; }
.tis-alert                                { border-radius:0; border-bottom-left-radius:.33em; border-bottom-right-radius:.33em; border-top:none; padding: .5em 1em 10px; color: #FFF; transform: translate(calc(50vw - 150%)); background-color: #0097C4; border-color:#3925D7; white-space: b;}
.tis-alert .tis-alert-close               { position:relative; top:-8px; left:8px; font-size: 20px; color: #DDD; cursor: pointer;}
.tis-alert .tis-alert-close:hover         { color: #FFF; }
.tis-alert-success                        { background-color: #4B8E00 !important; border-color:#25D739; white-space: b;}
.tis-alert-info                           { background-color: #0097C4 !important; border-color:#3925D7; white-space: b;}
.tis-alert-warn, .tis-alert-warning       { background-color: #DD9D39 !important; border-color:#D79225; white-space: b;}
.tis-alert-error                          { background-color: #dd4b39 !important; border-color:#d73925; }
.tis-alert-container                      { position:fixed; top:21px; z-index:99100; }
.tis-alert .MuiSvgIcon-root               { font-size: 16px; }

.tis-bar-chart                            { padding: 1em; border: 1px solid #d73925; margin: 1em 0; }
.tis-bar-chart .body                      { margin: 1em 0; }
.tis-bar-chart .title                     { text-align: center; font-weight: bold; font-size: 1.5em; }
.tis-bar-chart .tis-bar                   { border: 1px solid #CCC;  margin: .5em 0; position: relative; padding: .5em 1em; background-color: #CCC; height:3em; }
.tis-bar-chart .tis-bar-label             { font-weight: bold; margin-top: 1em; height: 1em; text-align: right; }
.tis-bar-chart .tis-inner-bar             { z-index: 100; padding: .5em; position: absolute; top: 0; left: 0; height: 100%; }

.tis-bar-chart-slim .body                 { margin: 0; }
.tis-bar-chart-slim .title                { text-align: center; font-weight: bold; }
.tis-bar-chart-slim .tis-bar-label        { font-size: 1rem; }
.tis-bar-chart-slim .tis-bar              { border: 1px solid #999; border-radius: .25em; margin: .25em 0; position: relative; padding: 0; background-color: #CCC; height:7px; }
.tis-bar-chart-slim .tis-inner-bar        { z-index: 100; padding: 0; border-radius: .25em; position: absolute; top: 0; left: 0; height: 100%; }

.tis-inline-message                       { padding: .5em 1em; border-radius: .5em; position: relative; margin: .5em 0 !important; }
.tis-inline-message-success               { font-size: 14px; margin-bottom: 1em; color: #FFF; background-color: #00a65a; border-color: #25D739 }
.tis-inline-message-info                  { font-size: 14px; margin-bottom: 1em; color: #FFF; background-color: #1565c0; border-color: #3925D7 }
.tis-inline-message-warn,
.tis-inline-message-warning               { font-size: 14px; margin-bottom: 1em; color: #FFF; background-color: #DD9D39; border-color: #D79225 }
.tis-inline-message-error                 { font-size: 14px; margin-bottom: 1em; color: #FFF; background-color: #dd4b39; border-color: #d73925 }
.tis-inline-message .MuiSvgIcon-root      { position: absolute; top: -.25em; right: -0.25em; padding:1px;border-radius: 1em; }
.tis-inline-message-success .MuiSvgIcon-root { background-color: #00a65a; }
.tis-inline-message-info .MuiSvgIcon-root { background-color: #1565c0; }
.tis-inline-message-success .MuiSvgIcon-root { background-color: #00a65a; }
.tis-inline-message-warn .MuiSvgIcon-root,
.tis-inline-message-warning .MuiSvgIcon-root { background-color: #DD9D39; }
.tis-inline-message-error .MuiSvgIcon-root { background-color: #dd4b39; }

.no-finding .tis-inner-bar { background-color: rgb(0, 196, 121); border: 1px solid rgb(28, 95, 42); }
.improvement-opportunity .tis-inner-bar { background-color: rgb(0, 196, 121); border: 1px solid rgb(28, 95, 42); }
.non-conformance .tis-inner-bar { background-color: rgb(233, 171, 12); border: 1px solid rgb(114, 79, 22); }
.conditional-non-conformance .tis-inner-bar { background-color: rgb(195, 82, 82); border: 1px solid rgb(104, 30, 30); }
.high-risk-non-conformance .tis-inner-bar { background-color: rgb(195, 82, 82); border: 1px solid rgb(104, 30, 30); }


.align-right                              { text-align: right; }
.audit-info-box                           { position: fixed; bottom: 3.7em; left: calc(100vw - 420px - 2em); background-color: #A00; padding: .25em 0; width: 410px; border-radius: 4px 4px 0 0; z-index: 1100; }
.sidebar-collapse .audit-info-box         { left: 0px; }
.MuiDialogContent-root .audit-info-box    { position: absolute; bottom: 0; left: 2em; }
.audit-info-box .MuiTypography-root       { color: #EFEFEF; font-size: 11.5px !important; }
.audit-info-box .MuiGrid2-root            { padding: 2px !important; }
.audit-info-box .label                    { text-align: right; }
.audit-info-box .value                    { text-align: left; }

.content-wrapper > .content.body          { padding: 0 2em 59px !important; min-height: calc(100vh - 59px) !important; }
.bold                                     { font-weight:bold; }
.box                                      { overflow:visible; }
.box-body                                 { padding: 1em; }
.box-default                              { position:relative; overflow: visible; }
.box-command-bar                          { float: right }
.box-title                                { float: left; color:#3c8dbc; font-weight:bold; }
.box-scrolling                            { height:40em; overflow-y:auto; }
.box-menu li .treeview-menu               { margin: 0 1px; background: #FFF;}
.btn-icon                                 { position: relative; top: 1px; }

.category                                 { margin-bottom: .5em !important;}
.category > .MuiCardHeader-root           { background-color: #efefef !important;}
.category .tis-collapse                   { width: 100%; text-align: center; cursor: pointer; padding: 0 0 2em; }


.center                                   { text-align:center; }
.clear                                    { clear:both; }
.clickable                                { cursor:pointer; }
.command-bar                              { margin:.25em 0; white-space:nowrap; position:absolute; right:1em; background-color:#ecf0f5; }
.command-bar a,
.command-bar button                       { margin-left: .75em; }
.command-bar-inline                       { margin: 0 1em 1em; }
.comand-bar-inline a,
.command-bar-inline button                { margin-left: .75em; }
.content                                  { position:relative; padding-bottom: 75px; }
.content .nav-container                   { position:fixed; right:2em; width:calc(100% - 270px - 4em ); z-index: 300; padding:.5em 0; margin:0 1em; }
.content nav                              { top:50px; left:230px; padding: .25em .5em; margin: 0; background-color: rgba(236, 240, 245, .95); color:#333; border:1px solid #DDD; border-radius:0; }
.content nav.fullscreen                   { top:0px; left:0px;}
.control-label                            { font-size:16px; color:#8aa4af; padding-left:0; padding-top:0; padding-bottom:0; margin:0;}

.content-wrapper > .content               { padding: 0 0.5rem 2.5em !important; top: 2.8em !important; }

@media only screen and (max-width : 767px) {
  .content nav                              { top:100px; left:0px; }
}

.delimited-row						                { border-top: 1px dashed #ddd !important;  }
.disabled                                 { background-color:#EEE; color:#999; }
.dropdown-menu>li>a                       { color: #b8c7ce; }
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:active                { color: #FFFFFF; }
.dropdown-menu>.active .autocomplete-content { color: #FFFFFF; }
.dropdown-menu>.active>a                  { background-color: #468AFD; }
.dropdown-menu .autocomplete-content      { color:#333333; }

.fixed                                    { position:relative; }
.fixed .main-header                       { top: 0; right: 0; left: 0; }
.fixed .left-side, 
.fixed .main-header, 
.fixed .main-sidebar                      { position: fixed; }
.form-control                             { border-radius:.33em !important; }
.form-control-autocomplete,
.form-control-date,
.form-control-no-info                     { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; padding-right: 0 !important; }
.form-control-feedback                    { right: 15px; }
.fs-36                                    { font-size: 36px !important; }
.form-control.fs-36                       { height: 60px !important; width:auto; }
.form-group                               { margin:0; }
.form-group div                           { padding-left:0; }

.glyphicon                                { color: #222d32; }

.h1, .h2, .h3, h1, h2, h3                 { margin-top: 12px; margin-bottom: 10px; }
.h4                                       { font-size: 16px; color: #8aa4af; margin:0; line-height:1.42857143; }
.h5                                       { font-size: 14px; color: #8aa4af; margin:0; }
.has-error .dropdown-toggle               { border-color:#d73925; background-color:#f2dede; }
.has-error .form-control                  { border-color:#d73925; }
.has-error .form-control-feedback.glyphicon { right:18px; }
.has-error .input-group-addon .glyphicon  { color: transparent; }
.hidden                                   { display:none; }
.highlight                                { background-color:rgba(255, 255, 0, 1); transition-timing-function: ease-in; animation: highlight-fade 3000ms forwards; }
@keyframes highlight-fade                 { from {background-color:rgba(255, 255, 0, 1);}     to {background-color:rgba(255, 255, 0, 0);} }

.info                                     { font-size:11px; color:#999;}
.inline-image                             { position:relative; top:-2px; }
.input-group-addon                        { background-color:#efefef !important; border-top-right-radius: .33em !important; border-bottom-right-radius: .33em !important; height:34px !important; }
.invalid-tab *                            { color:#dd4b39 !important; }

.l0                                       { left:0; }
.l-0_5em                                  { left:-0.5em; }
.l-6px                                    { left:-6px; }
.legend                                   { background-color: #FFFFFF; border: 1px solid rgba(0,0,0,0.1); padding: .3em 1em; margin: 1em; }
.legend .contents>div                     { margin:.25em 0; }
.legend .color-block                      { float:left; margin: 0 3px; width:20px; }
.legend label                             { padding:0; font-size:10px; display:block;}
.loading                                  { display:block; position:absolute; left:0; right:0; top:0; bottom:0; background:rgba(255, 255, 255, 0.8); -webkit-transition:all .3s ease; transition:all .3s ease; z-index:99000; pointer-events:none; width:100%; height:100%;}
.loading > div                            { position:absolute; display:block; text-align:center; width:100%; top:30%; left:0; font-size:inherit; color:rgba(0, 0, 0, 0.6); -webkit-transform:translateY(-52%); transform:translateY(-52%); -webkit-transition:all .3s cubic-bezier(.25, .46, .45, .94); transition:all .3s cubic-bezier(.25, .46, .45, .94) }
.loading > div > img                      { position:relative; top: -1px; left:3px; }
.loading.active                           { opacity: 1; pointer-events: all; }
.loading.active > div                     { -webkit-transform: translateY(50%); transform: translateY(50%) }

@media only screen and (max-width : 767px) {
  .main-footer                              { padding:0.25em; height: 59px; }
}

.main-header                              { position: fixed !important; max-height: 100px; z-index: 1030 !important; margin: 0 !important; border: none !important; height: 46px; background-color: #d73925; }
.main-header .logo                        { transition: width .3s ease-in-out; display: block; float: left; font-size: 20px; text-align: center; width: 270px; font-family: Helvetica Neue,Helvetica,Arial,sans-serif; padding: 0 15px; font-weight: 300; overflow: hidden; background-color: #d73925; color: #fff; border-bottom: 0 solid transparent; }
.main-header .navbar                      { background-color: #d73925; }
.main-header .sidebar-toggle              { float: left; background-color: initial; height:30px; color: #FFF; }
.main-sidebar                             { background-color: #222d32; height: 100vh; overflow-y: hidden; position: fixed !important; top: 0; left: 0; padding-top: 50px; min-height: 100%; width: 270px !important; z-index: 810 !important; transition: transform .3s ease-in-out,width .3s ease-in-out; }

.m0                                       { margin:0 !important; }
.mb-0_5em                                 { margin-bottom: 0.5em !important; }
.mb-1em                                   { margin-bottom: 1em !important; }
.mb-2em                                   { margin-bottom: 2em !important; }
.mb-3em                                   { margin-bottom: 3em !important; }
.ml-0_5em                                 { margin-left: 0.5em !important; }
.ml-1em                                   { margin-left: 1em !important; }
.ml-2em                                   { margin-left: 2em !important; }
.ml-3em                                   { margin-left: 3em !important; }
.mr-0_5em                                 { margin-right: 0.5em !important; }
.mr-1em                                   { margin-right: 1em !important; }
.mr-2em                                   { margin-right: 2em !important; }
.mr-3em                                   { margin-right: 3em !important; }
.mr-50px                                  { margin-right: 50px !important; }
.mt0                                      { margin-top:0 !important; }
.mt-0_5em                                 { margin-top: 0.5em !important; }
.mt-1em                                   { margin-top: 1em !important; }
.mt-2em                                   { margin-top: 2em !important; }
.mt-3em                                   { margin-top: 3em !important; }
.mt-50px                                  { margin-top: 50px !important; }
.mini-stat-box                            { width:3.75em; padding:.2em .75em; font-size:28px; border-radius:2px; line-height:0; margin:.25em  auto; position:relative; display:inline-block; }
.mini-stat-box h3                         { font-size: 32px; }
.mini-stat-box .text                      { font-size:45%; vertical-align:top; position:relative; top:.5em; white-space:nowrap; }
.MuiAccordionSummary-root                 { background-color: rgb(244, 246, 249) !important; border: 1px solid #CCC !important; border-radius: 4px; }
.MuiAccordion-root                        { box-shadow: none !important; }
.MuiAccordionDetails-root                 { padding: 2em 1em !important; }
.MuiAutocomplete-popper                   { background-color: #FFFFFF !important; border: 1px solid #CCC; border-radius: 0 0 4px 4px; }
.MuiCollapse-root                         { background-color: #FFFFFF !important; box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important; }
.MuiFormLabel-root                        { background-color: #FFFFFF; padding: 0 .25em !important; }
.MuiPaper-root::before                    { border: none !important; background-color: transparent !important; }
.MuiPaper-root                            { margin: 1em 0; }
.MuiAccordion-root.Mui-expanded           { margin-top: 0 !important; margin-bottom: 0 !important; }                

.navbar-fixed-bottom                      { -webkit-transition: -webkit-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; -moz-transition: -moz-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; -o-transition: -o-transform 0.3s ease-in-out, left 0.3s ease-in-out !important; transition: transform 0.3s ease-in-out, left 0.3s ease-in-out !important; bottom: 0 !important; position: fixed !important; left: 0 !important; right: 0 !important; z-index: 9000;}
.navbar-fixed-top                         { position:relative; -webkit-transition: -webkit-transform 0.3s ease-in-out, left 0.3s ease-in-out; -moz-transition: -moz-transform 0.3s ease-in-out, left 0.3s ease-in-out; -o-transition: -o-transform 0.3s ease-in-out, left 0.3s ease-in-out; transition: transform 0.3s ease-in-out, left 0.3s ease-in-out; }
.navbar-static-top                        { z-index: 1000; border-width: 0 0 1px; }

.overflow-visible                         { overflow:visible !important; }

.p0                                       { padding:0; }
.page-content                             { padding-top:0em; height:600px;}
.page-content-adjusted                    { padding-top:5.5em; }

.panel .panel-body                        { position:relative; }
.panel .panel-body .loading  > div        { top:40%; }
.panel-header                             { padding-left: 1em;}
.pb-1em                                   { padding-bottom: 1em; }
.pr-1em                                   { padding-right: 1em; }
.pt0                                      { padding-top:0 !important; }
.ptb-0_5em                                { padding-top: .5em; padding-bottom: .5em; }
.ptb-1em                                  { padding-top: 1em; padding-bottom: 1em; }

.react-datepicker                         { cursor:pointer; font-size:12px !important; }
.react-datepicker__close-icon             { display: none; }
.react-datepicker__input-container        { width:100%; }

.ReactTable.ie-table-short                { height:200px; }
.ReactTable.ie-table-tall                 { height:780px; }
.ReactTable,
.ReactTable .-pagination                  { background-color:#ffffff; }
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td              { overflow: visible;}
.ReactTable .rt-th .btn                   { font-size: 75%; }
.ReactTable .rt-th .dropdown-menu         { min-width:80px; background-color: #333; }
.ReactTable .rt-th .dropdown-menu>li>a:focus,
.ReactTable .rt-th .dropdown-menu>li>a:hover {background-color: #222d32;}
.relative                                 { position: relative; }
.report-summary-panel                     { border: 1px solid #CCCCCC; background-color: #FFFFFF; border-radius: 4px; padding:.5em; margin: 1em; height: 12em; overflow:hidden; }
.report-summary-panel div div             { margin:0; padding:0; }
.report-summary-panel .title              { font-size:14px; font-weight:400; }
.report-summary-panel .icon-open          { cursor:pointer; }
.report-summary-panel .subtitle           { font-weight: bold; }
.report-summary-panel .label,
.report-summary-panel .value              { color:#333333; font-size:12px; text-align:left; padding:0 1em; overflow:hidden; display:inline-block; width:100%; }
.roles-select                             { width:310px; }

form .row                                 { padding:0.33em 0; min-height:3.17em; margin:0; }
.row                                      { padding:0.33em 0; margin:0; }
.row.row-odd                              { background-color: transparent; }
.row.row-even                             { background-color: rgba(144, 144, 144, 0.1); }

.saving                                   { display:block; position:absolute; left:0; right:0; top:0; bottom:0; background:rgba(255, 255, 255, 0.8); -webkit-transition:all .3s ease; transition:all .3s ease; z-index:99000; pointer-events:none; width:100%; height:100%;}
.saving > div                             { position:absolute; display:block; text-align:center; width:100%; top:30%; left:0; font-size:inherit; color:rgba(0, 0, 0, 0.6); -webkit-transform:translateY(-52%); transform:translateY(-52%); -webkit-transition:all .3s cubic-bezier(.25, .46, .45, .94); transition:all .3s cubic-bezier(.25, .46, .45, .94) }
.saving > div > img                       { position:relative; top: -1px; left:3px; }
.saving.active                            { opacity: 1; pointer-events: all; }
.saving.active > div                      { -webkit-transform: translateY(50%); transform: translateY(50%) }

.Select-menu-outer                        { z-index:840; }
.Select-option                            { padding-left: .25em !important; padding-right: .25em !important; }
.session-expired-overlay                  { display:block; position:absolute; left:0; right:0; top:0; bottom:0; background:rgba(34, 45, 50, 0.9); z-index:99000; pointer-events:none; width:100%; height:100%; }
.shadow                                   { box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.sidebar-collapse .navbar-fixed-top       { left:0 !important; }
.sidebar-collapse .main-sidebar, .sidebar-collapse .main-sidebar::before { margin-left: -270px !important; }
.sidebar-collapse .content .nav-container { width:calc(100% - 4em); }
.sidebar                                  { padding: 0 !important; }
.sidebar-menu li                          { width: 270px; position: relative; }
.sidebar-menu li .submenu-icon            { position: absolute; right: 10px; }
.sidebar-menu li.divider                  { border-bottom: 2px solid #d73925; padding: .25em; margin:0 1em; }
.sidebar-menu li .treeview-menu           { margin: 0 1px; background: #2c3b41; padding-left: .6em; }
.sidebar-menu li .treeview-menu li        { margin-left:10px; }
.sidebar-menu li a                        { color: #8aa4af; padding: 8px 4px 8px 1em; display: block; white-space: nowrap; line-height: 1.42857143; }
.sidebar-menu li a:hover,
.sidebar-menu li a:active                 { color: #fff; }
.sidebar-menu li a .MuiSvgIcon-root       { font-size: medium; margin: 0 .25em .25em; }
.snapshot-mode-label                      { color: #FFF; position:fixed; top:102px; right:0; z-index:300; background-color:#f39c12; padding: 0.4em 1em; }
.snapshot-mode-label .glyphicon           { color: #FFF; }
.stats                                    { font-size: 28px; }
.status-losing                            { background-color: #dd4b39 }
.status-tbd                               { background-color: #d2d6de; }
.status-winning                           { background-color: #00a65a; }
.status-is-winning                        { color:#FFFFFF; padding: 2px; width:100%; }

sup                                       { font-size:10px; top:-.75em; font-size:16px; }

.tab-content                              { background-color:#ffffff; padding: 1em; border: 1px solid #ddd; border-top:none; border-bottom-left-radius:4px; border-bottom-right-radius:4px; min-height:20em; }
.tis-commandbar button                    { margin: .275em .5em;  padding: .5em .5em; height: 2.8em;}
.tis-commandbar .MuiSelect-select         { padding: .5em .5em; }
.tis-breadcrumb-container                 { margin: 0 !important; }
.tis-breadcrumb                           { top: 0 !important; left: 0 !important; padding: .25em .5em !important; }
.tis-breadcrumb h1                        { font-size: 28px !important; margin: 0 !important; padding: 0 !important; vertical-align: bottom !important; }
.tis-breadcrumb .icon                     { font-size: 28px !important; margin: 0 !important; position: relative !important; top: -3px !important; }
.tis-form-button                          { margin: 1em 0em !important;  }
.tis-form-field                           { margin: .25em 0em !important; }
.tis-form-table                           { margin: .5em 0em !important; }
.tis-form-text                            { margin: .5em .25em 0em !important; font-size: 14px !important; }
.tis-form-title                           { font-weight: bold !important; }
.tis-mfi-guidance                         { position:relative; margin: 0 auto; min-width: 16em; max-width: 50%; background-color: #CCCCFF !important; z-index: 3000 !important; border: 1px solid #6666AA; border-radius: 1em !important; box-shadow: 0px 8px 4px -4px rgba(0,0,0,0.2), 0px 4px 4px 0px rgba(0,0,0,0.14), 0px 4px 12px 0px rgba(0,0,0,0.12) !important; }
.tis-mfi-guidance h2                      { padding: .25em 0 .25em .5em; font-size: 24px; }
.tis-help-icon                            { font-size: 2rem !important; color:#999999; border-radius: 40px; border:3px solid transparent; cursor: pointer; }
.tis-help-icon:hover                      { font-size: 2rem !important; color:#6666AA; border-radius: 40px; border:3px solid #6666AA !important; }
.tis-page                                 { width: 100%; }
.treeview-menu                            { list-style-type: none; }

.unclickable                              { pointer-events:none; }

.visible                                  { display:block !important; }
.width-1                                  { width:8.33%; }
.width-2                                  { width:16.66%; }
.width-3                                  { width:25%; }
.width-4                                  { width:33.33%; }
.width-5                                  { width:41.66%; }
.width-6                                  { width:50%; }
.width-7                                  { width:58.33%; }
.width-8                                  { width:66.67%; }
.width-9                                  { width:75%; }
.width-10                                 { width:83.33%; }
.width-11                                 { width:91.66%; }
.width-12                                 { width:100%; }
.icon-bg                                  {color: #3c8dbc;}
